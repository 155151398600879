import React from 'react';

type IconType = React.ComponentProps<'svg'>;

const SearchBannerIcon = ({ ...rest }: IconType) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="119"
        height="96"
        fill="none"
        viewBox="0 0 107 96"
    >
        <path
            fill="#3A3F50"
            d="M106.86 5.305V96H25V5.305C25 2.388 27.376 0 30.28 0h71.168c2.903 0 5.412 2.388 5.412 5.305"
        ></path>
        <path
            fill="#484D5E"
            d="M56.96 18H36.985v6H56.96zM86.923 41H36.985v10h49.938zM88.92 54H36.985v10H88.92zM87.422 76H43.477a6.496 6.496 0 0 0-6.492 6.5c0 3.59 2.907 6.5 6.492 6.5h43.945a6.496 6.496 0 0 0 6.492-6.5c0-3.59-2.906-6.5-6.492-6.5"
        ></path>
        <path
            fill="#5E6272"
            d="M83.927 31.402c5.516 0 9.987-4.477 9.987-10s-4.471-10-9.987-10-9.988 4.478-9.988 10 4.472 10 9.988 10"
        ></path>
        <path
            fill="#3A3F50"
            d="M63.03 78.423 47.377 62.69l3.589-3.609 15.64 15.744z"
        ></path>
        <path
            fill="#3A3F50"
            d="M68.471 83.958 56.47 71.886a2.6 2.6 0 0 1 0-3.66 2.566 2.566 0 0 1 3.638 0L72.109 80.3a2.6 2.6 0 0 1 0 3.659 2.566 2.566 0 0 1-3.638 0"
        ></path>
        <path
            fill="#5E6272"
            d="M67.617 84.73 52.175 69.196a.943.943 0 0 1 0-1.32l3.947-3.97a.93.93 0 0 1 1.311 0L72.875 79.44a.943.943 0 0 1 0 1.32l-3.947 3.97a.93.93 0 0 1-1.311 0M29.659 12C13.277 12 0 25.354 0 41.832c0 16.466 13.277 29.833 29.647 29.833 16.382 0 29.659-13.355 29.659-29.833S46.029 12 29.659 12m0 54.139c-13.153 0-23.806-10.89-23.806-24.32 0-13.428 10.653-24.306 23.806-24.306s23.806 10.89 23.806 24.32c0 13.428-10.666 24.306-23.806 24.306"
        ></path>
    </svg>
);

export default SearchBannerIcon;
