import { ChangeEvent, MouseEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Button } from '../../components/Button';
import { SmallCircleContainer } from '../../components/CircleContainer/styles';
import ConfirmModal from '../../components/ConfirmModal';
import Input from '../../components/Form/Input/input';
import Label from '../../components/Form/Label';
import { Form, FormGroup } from '../../components/Form/styles';
import Header from '../../components/Header';
import { Box } from '../../components/Layout';
import { LoadingModal } from '../../components/LoadingModal';
import { PlusLabel } from '../../components/PlusLabel';
import constants from '../../constants';
import { AppState, useAppState } from '../../contexts/App';
import { useSite } from '../../contexts/Site';
import useCheckUrl from '../../hooks/CheckUrl';
import TrashIcon from '../../icons/Trash';
import { Site } from '../../model/site';
import { api } from '../../services/api';
import CategoriesInputSelect from '../Editor/components/CategoriesInputSelect';
import { AdvancedConfigs } from './components/AdvancedConfigs';
import {
    ToggleSwitchSection,
    Container,
    FooterBox,
    FooterButtonSection,
} from './styles';

import { classNames } from '../../utils/styles';
import { Link } from 'react-router-dom';
import ToggleSwitch from '../../components/ToggleSwitch';
import * as Fonts from '../../components/Fonts';
import { InputMessageSection } from '../Editor/pages/Publish/styles';
import { AxiosError } from 'axios';

const SettingsPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { appState, setAppState } = useAppState();
    const { site } = useSite();

    const [enabled, setEnabled] = useState<boolean>(
        !appState?.editingSiteDisabled
    );

    const { url, onChangeUrl, urlReady, loadingUrl, urlNotAvailable } =
        useCheckUrl(appState?.editingSiteId as string, site?.url || '');

    const [title, setTitle] = useState<string>(site?.title || '');
    const [category, setCategory] = useState<string>(site?.category || '');

    const [hideSignature, setHideSignature] = useState<boolean>(
        !!site?.hideSignature
    );

    const [showCofirmRemoveModal, setShowCofirmRemoveModal] = useState(false);
    const [isPublishError, setIsPublishError] = useState(false);

    const [isSaving, setIsSaving] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);

    const [isShowConfirmNonChangesSaved, setShowConfirmNonChangesSaved] =
        useState(false);

    const isSaveDisabled = !url || !title || !urlReady;

    const isPlus = appState.editingSiteSubscription?.type === 'PLUS';

    const goPremiumUrl = `/subscription/features?site=${appState.editingSiteId}`;

    const saveHandler = () => {
        publishSite();
    };

    const publishSite = async () => {
        setIsSaving(true);

        try {
            await api.publish(
                appState?.editingSiteId as string,
                { ...site, url, title, category, hideSignature } as Site
            );
            navigate(-1);
        } catch (e) {
            if (
                e instanceof AxiosError &&
                e.response &&
                e.response.status === 409
            ) {
                setIsPublishError(true);
            }
        } finally {
            setIsSaving(false);
        }
    };

    const remove = async () => {
        setIsRemoving(true);
        setShowCofirmRemoveModal(false);

        try {
            await api.deleteSite(appState?.editingSiteId as string);

            setAppState({
                ...appState,
                notRedirectOnEmptySites: true,
            } as AppState);

            navigate(-1);
        } catch (e) {
            setIsRemoving(false);
        }
    };

    const handleEnabledChange = useCallback(
        (enabled: boolean) => {
            setEnabled(enabled);

            if (enabled) {
                api.enableSite(appState?.editingSiteId as string);
            } else {
                api.disableSite(appState?.editingSiteId as string);
            }
        },
        [enabled, appState]
    );

    const backClickHandler = () => {
        if (
            site?.url !== url ||
            site.title !== title ||
            site.category !== category ||
            site.hideSignature !== hideSignature
        ) {
            setShowConfirmNonChangesSaved(true);
        } else {
            navigate(-1);
        }
    };

    const handleHideSignatureChange = useCallback((hideSignature: boolean) => {
        setHideSignature((hideSignature) => !hideSignature);
    }, []);

    const handleHideSignatureClick = useCallback(() => {
        if (isPlus) {
            return;
        }

        navigate(goPremiumUrl);
    }, [navigate, appState]);

    return (
        <>
            {(isSaving || isRemoving) && <LoadingModal />}
            <Container>
                <Header
                    title={t('pages.settings.header.title')}
                    onClickBack={backClickHandler}
                    rightElement={isPlus && <PlusLabel />}
                />
                <Form>
                    <Box borderBottom>
                        <FormGroup>
                            <ToggleSwitchSection>
                                <h6>
                                    {enabled
                                        ? t('pages.settings.form.active')
                                        : t('pages.settings.form.inactive')}
                                </h6>
                                <ToggleSwitch
                                    value={enabled}
                                    setValue={handleEnabledChange}
                                />
                            </ToggleSwitchSection>
                        </FormGroup>
                        <FormGroup>
                            <ToggleSwitchSection isPlus={isPlus}>
                                <div>
                                    <h6>
                                        {t('pages.settings.form.showSignature')}
                                    </h6>

                                    <ToggleSwitch
                                        value={!hideSignature}
                                        setValue={handleHideSignatureChange}
                                        disabled={!isPlus}
                                        onClick={handleHideSignatureClick}
                                    />
                                </div>
                                {!isPlus && (
                                    <Link
                                        className="premium-banner"
                                        to={goPremiumUrl}
                                    >
                                        {t('pages.settings.form.upgrade')}
                                    </Link>
                                )}
                            </ToggleSwitchSection>
                        </FormGroup>
                    </Box>
                    <Box>
                        <FormGroup>
                            <Input
                                type="text"
                                id="url"
                                maxLength={30}
                                ignoreLengthInfo
                                value={url}
                                prefix={`${constants.nameSite}/`}
                                required
                                autoCapitalize="none"
                                loading={loadingUrl}
                                onChange={(e) =>
                                    onChangeUrl(
                                        e.target.value.replace(/\s/g, '')
                                    )
                                }
                                infoMessage={
                                    urlNotAvailable || (isPublishError && url)
                                        ? t(
                                              'pages.settings.form.url.notAvailable'
                                          )
                                        : ''
                                }
                                label={t('pages.settings.form.url.label')}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input
                                type="text"
                                id="title"
                                required
                                maxLength={24}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                label={t('pages.settings.form.title')}
                                autoCapitalize="on"
                            />
                        </FormGroup>
                        <FormGroup>
                            <CategoriesInputSelect
                                id="category"
                                value={category}
                                onChange={setCategory}
                            />
                            <Label
                                htmlFor="category"
                                text={t('pages.settings.form.category')}
                            />
                        </FormGroup>
                    </Box>
                    {isPlus && (
                        <>
                            <Box className="npt">
                                <AdvancedConfigs />
                            </Box>
                            <Box borderBottom className="npt">
                                <Button
                                    type="OUTLINE"
                                    size="LG"
                                    onClick={() =>
                                        navigate(
                                            `/subscription/detail?site=${appState.editingSiteId}`
                                        )
                                    }
                                >
                                    {t(
                                        'pages.settings.actions.manageSubscription.title'
                                    )}
                                </Button>
                            </Box>
                        </>
                    )}
                    <FooterBox>
                        <FooterButtonSection>
                            {isPlus ? (
                                <div />
                            ) : (
                                <SmallCircleContainer
                                    onClick={() =>
                                        setShowCofirmRemoveModal(true)
                                    }
                                    $color="var(--color-neutral-base)"
                                    cursor="pointer"
                                >
                                    <TrashIcon
                                        color="var(--color-neutral-white)"
                                        size={20}
                                    />
                                </SmallCircleContainer>
                            )}
                            <Button
                                type="INFO"
                                size="LG"
                                disabled={isSaveDisabled}
                                onClick={() => saveHandler()}
                            >
                                {isSaving
                                    ? t(
                                          'pages.settings.actions.save.button.titleSaving'
                                      )
                                    : t(
                                          'pages.settings.actions.save.button.title'
                                      )}
                            </Button>
                        </FooterButtonSection>
                    </FooterBox>
                </Form>
                {showCofirmRemoveModal && (
                    <ConfirmModal
                        type="DANGER"
                        title={t(
                            'pages.settings.actions.remove.confirmModal.title'
                        )}
                        cancelTitle={t(
                            'pages.settings.actions.remove.confirmModal.cancelButton'
                        )}
                        confirmButtonTitle={t(
                            'pages.settings.actions.remove.confirmModal.confirmButton'
                        )}
                        onCancel={() => setShowCofirmRemoveModal(false)}
                        onConfirm={remove}
                    />
                )}
                {isShowConfirmNonChangesSaved && (
                    <ConfirmModal
                        type="DANGER"
                        title={t(
                            'pages.settings.actions.exit.confirmExitNonSaved.title'
                        )}
                        cancelTitle={t(
                            'pages.settings.actions.exit.confirmExitNonSaved.cancelButton'
                        )}
                        confirmButtonTitle={t(
                            'pages.settings.actions.exit.confirmExitNonSaved.confirmButton'
                        )}
                        onCancel={() => setShowConfirmNonChangesSaved(false)}
                        onConfirm={() => navigate(-1)}
                    />
                )}
            </Container>
        </>
    );
};

export default SettingsPage;
