import { signOut } from 'aws-amplify/auth';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { Button } from '../../components/Button';
import { MicroCircleContainer } from '../../components/CircleContainer/styles';
import ConfirmModal from '../../components/ConfirmModal';
import Header from '../../components/Header';
import { Box } from '../../components/Layout';
import { LoadingModal } from '../../components/LoadingModal';
import { useAppState } from '../../contexts/App';
import BackIcon from '../../icons/Back';
import { api, User } from '../../services/api';
import { ContactIcon } from './icons/Contact';
import { FeedbackIcon } from './icons/Feedback';
import { LangIcon } from './icons/Lang';
import { Container } from './style';

export const AccountPage = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const currentLang = useRef(
        i18n.language.toUpperCase().includes('PT') ? 'PT' : 'EN'
    );

    const { appState } = useAppState();

    const [isLoading, setLoading] = useState(true);
    const [user, setUser] = useState<User>();

    const [isShowLangModal, setShowLangModal] = useState(false);

    const loadUser = async () => {
        const user = await api.getUser();
        setUser(user);
        setLoading(false);
    };

    const toggleLang = useCallback(() => {
        currentLang.current = currentLang.current === 'PT' ? 'EN' : 'PT';
        setShowLangModal(false);
        setLoading(true);

        i18n.changeLanguage(currentLang.current.toLowerCase()).then(() => {
            setLoading(false);
            api.updateLang(currentLang.current); //call and forget
        });
    }, [i18n]);

    const clickSubscriptions = useCallback(() => {
        if (appState.subscriptionSiteIds.length === 1) {
            return navigate(
                `/subscription/detail?site=${appState.subscriptionSiteIds[0]}`
            );
        }
        navigate('/subscription/list');
    }, [appState.subscriptionSiteIds, navigate]);

    useEffect(() => {
        loadUser();
    }, []);

    return isLoading ? (
        <LoadingModal />
    ) : (
        <Container>
            <Header title={t('pages.account.header.title')} />
            <Box className="user-info" borderBottom>
                <div>
                    <h4>{user?.name}</h4>
                    <p>{user?.email}</p>
                </div>
                <div className="lang" onClick={() => setShowLangModal(true)}>
                    <div>
                        <LangIcon /> <p>{currentLang.current}</p>
                    </div>
                </div>
            </Box>
            {appState.isPROUser && (
                <Box className="npb">
                    <div className="subscriptions" onClick={clickSubscriptions}>
                        <div>
                            <h6>
                                {t('pages.account.actions.subscriptions.title')}
                            </h6>
                            <div className="badge">Plus</div>
                        </div>
                        <MicroCircleContainer $color="#3A3F50">
                            <BackIcon color="white" width={11} height={10} />
                        </MicroCircleContainer>
                    </div>
                </Box>
            )}
            <Box className="actions npb">
                {appState.isPROUser && (
                    <div className="action" onClick={() => navigate('/help')}>
                        <ContactIcon />
                        <h6>{t('pages.account.actions.help')}</h6>
                    </div>
                )}
                <div className="action" onClick={() => navigate('/feedback')}>
                    <FeedbackIcon />
                    <h6>{t('pages.account.actions.feedback')}</h6>
                </div>
            </Box>
            <Box>
                <Button
                    size="LG"
                    type="OUTLINE"
                    className="logout-button"
                    onClick={() => signOut()}
                >
                    {t('actions.logout')}
                </Button>
            </Box>
            <Box className="legal">
                <a
                    href="https://www.keepo.bio/politica-de-privacidade/"
                    target="_blank"
                >
                    <p>{t('pages.account.actions.legal')}</p>
                </a>
            </Box>
            {isShowLangModal && (
                <ConfirmModal
                    outsideExit
                    type="INFO"
                    title={t('pages.account.actions.lang.confirmModal.message')}
                    confirmButtonTitle={t(
                        'pages.account.actions.lang.confirmModal.buttonTitle'
                    )}
                    onConfirm={toggleLang}
                    onCancel={() => setShowLangModal(false)}
                />
            )}
        </Container>
    );
};
