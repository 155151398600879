import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import ConfirmModal from '../../components/ConfirmModal';
import * as Fonts from '../../components/Fonts';
import { Box } from '../../components/Layout';
import { LoadingModal } from '../../components/LoadingModal';
import { useAppState } from '../../contexts/App';
import { ShapeIcon } from '../../icons/Shape';
import SymbolKeepoIcon from '../../icons/SymbolKeepo';
import { createNewSite, Site } from '../../model/site';
import { api, ApiSiteResponse, Subscription } from '../../services/api';
import { classNames } from '../../utils/styles';
import { uuidv4 } from '../../utils/uuid';
import { ActiveSubscriptionBanner } from './components/ActiveSubscriptionBanner';
import CardSite from './components/CardSite';
import { EmptyState } from './components/EmptyState';
import { Footer } from './components/Footer';
import LoadingState from './components/LoadingState';
import { NewSiteModal } from './components/NewSiteModal';
import { PremiumBanner } from './components/PremiumBanner';
import { Container, HeaderBox, LogoSection, SitesSection } from './styles';
import { SearchBanner } from './components/SearchBanner';

const orderByPremium = (a: ApiSiteResponse, b: ApiSiteResponse) =>
    a.subscription?.type === b.subscription?.type
        ? 0
        : a.subscription?.type === 'PLUS'
          ? -1
          : 1;

const orderByEnable = (a: ApiSiteResponse, b: ApiSiteResponse) =>
    a.disabled === b.disabled ? orderByPremium(a, b) : !a.disabled ? -1 : 1;

type Props = {
    setSite: (site: Site) => void;
};

const showPremiumBannerLocalStorage = localStorage.getItem('premium-banner');

const SitesPage = ({ setSite }: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { appState, setAppState } = useAppState();

    const [nonPublishedRestoringDraft, setNonPublishedRestoringDraft] =
        useState<ApiSiteResponse>();

    const [draftAvailableToActiveOnBaner, setDraftAvailableToActiveOnBaner] =
        useState<ApiSiteResponse>();

    const [siteRestoringDraft, setSiteRestoringDraft] =
        useState<ApiSiteResponse>();

    const [showMessageConfirmCreatingPlus, setShowMessageConfirmCreatingPlus] =
        useState(false);

    const [loadingPage, setLoadingPage] = useState(true);
    const [sites, setSites] = useState<ApiSiteResponse[]>([]);
    const [allLoadedSites, setAllLoadedSites] = useState<ApiSiteResponse[]>([]);

    const [user, setUser] = useState<string>();

    const [siteEnabling, setSiteEnabling] = useState<ApiSiteResponse>();

    const [isRequestingSiteEnable, setRequestingSiteEnable] = useState(false);
    const [isShowAddModal, setShowAddModal] = useState(false);
    const [showPremiumBanner, setShowPremiumBanner] = useState(() => {
        return showPremiumBannerLocalStorage === 'displayed';
    });

    const createNewSiteHandler = () => {
        const nonPublishedSite = allLoadedSites.find((item) => !item.site);
        const site = nonPublishedSite?.draft || createNewSite();

        setSite(site);
        setAppState({
            ...appState,
            editingSiteId: nonPublishedSite?.siteId || uuidv4(),
            editingSiteModified: false,
            published: false,
            editingSiteDisabled: false,
            editingSiteSubscription: nonPublishedSite?.subscription || {
                type: 'FREE',
            },
        });

        navigate('/editor/title');
    };

    const isPlusSubscriptMarkedAsSeen = useCallback((siteId: string) => {
        try {
            const item = localStorage.getItem(
                `${siteId}:plus-subscription-seen`
            );
            return !!item;
        } catch (e) {
            return false;
        }
    }, []);

    const markPlusSubscriptionAsSeen = useCallback((siteId: string) => {
        localStorage.setItem(`${siteId}:plus-subscription-seen`, 'true');
    }, []);

    const onClickEditCardSite = (site: ApiSiteResponse) => {
        if (site.draft) {
            setSiteRestoringDraft(site);
        } else {
            edit(
                site.site,
                site.siteId,
                '/editor/sections',
                false,
                true,
                site.disabled,
                site.subscription
            );
        }
    };

    const confirmUseDraftAndEdit = () => {
        const { siteId, draft, disabled, subscription } =
            siteRestoringDraft as ApiSiteResponse;
        const route = draft.title ? '/editor/sections' : '/editor/title';
        edit(draft, siteId, route, true, true, disabled, subscription);
    };

    const deleteDraftAndEdit = () => {
        const { site, siteId, disabled } =
            siteRestoringDraft as ApiSiteResponse;
        api.deleteDraf(siteId);
        edit(site, siteId, '/editor/sections', false, true, disabled);
    };

    const confirmUseDraftNonPublishedSite = () => {
        const { siteId, draft, disabled, subscription } =
            nonPublishedRestoringDraft as ApiSiteResponse;
        const route = draft.title ? '/editor/sections' : '/editor/title';
        edit(draft, siteId, route, true, false, disabled, subscription);
    };

    const discardDraftNonPublishedSite = () => {
        const { siteId, subscription } =
            nonPublishedRestoringDraft as ApiSiteResponse;

        if (subscription?.type !== 'PLUS') {
            api.deleteDraf(siteId); //call and forget
        } else {
            markPlusSubscriptionAsSeen(siteId);
            setDraftAvailableToActiveOnBaner(nonPublishedRestoringDraft);
        }

        setNonPublishedRestoringDraft(undefined);
    };

    const edit = useCallback(
        (
            site: Site,
            siteId: string,
            nextPage: string,
            editingSiteModified: boolean,
            published: boolean,
            editingSiteDisabled: boolean,
            subscription: Subscription = { type: 'FREE' }
        ) => {
            setAppState({
                ...appState,
                editingSiteId: siteId,
                editingSiteModified,
                published,
                editingSiteDisabled,
                notRedirectOnEmptySites: true,
                editingSiteSubscription: subscription,
            });

            setSite(site);
            navigate(nextPage);
        },
        [appState, navigate, setAppState, setSite]
    );

    const confirmUsePlusDraftNonPublishedSite = useCallback(() => {
        const { siteId, draft, disabled, subscription } =
            draftAvailableToActiveOnBaner as ApiSiteResponse;
        edit(
            draft,
            siteId,
            '/editor/title',
            true,
            false,
            disabled,
            subscription
        );
    }, [draftAvailableToActiveOnBaner, edit]);

    const loadSites = async () => {
        const [sites, userInfo] = await Promise.all([
            api.getSites(),
            api.getUser(),
        ]);

        setUser(userInfo.givenName);

        const subscriptionSiteIds = sites
            .filter(({ subscription }) => subscription?.type === 'PLUS')
            .map(({ siteId }) => siteId);

        const isPROUser = subscriptionSiteIds.length > 0;

        setAppState({ ...appState, isPROUser, subscriptionSiteIds });

        const publishedSites = sites
            .filter(({ site }) => !!site)
            .sort(orderByEnable);

        const nonPublisedSite = sites.find(({ site, subscription, siteId }) => {
            if (site) {
                return false;
            }
            if (!site && subscription?.type !== 'PLUS') {
                return true;
            }
            return !isPlusSubscriptMarkedAsSeen(siteId);
        });

        const draftAvailableToActiveOnBaner = sites.find(
            ({ site, subscription, siteId }) => {
                if (!!site || subscription?.type !== 'PLUS') {
                    return false;
                }
                return isPlusSubscriptMarkedAsSeen(siteId);
            }
        );

        setDraftAvailableToActiveOnBaner(draftAvailableToActiveOnBaner);

        setAllLoadedSites(sites);
        setSites(publishedSites);
        setNonPublishedRestoringDraft(nonPublisedSite);
        setLoadingPage(false);
    };

    const enableKeepo = async () => {
        setRequestingSiteEnable(true);
        setSiteEnabling(undefined);

        try {
            await api.enableSite(siteEnabling?.siteId as string);
            const copy = [...sites];
            const index = copy.findIndex(
                (site) => site.siteId === siteEnabling?.siteId
            );

            copy[index].disabled = false;
            setSites(copy.sort(orderByEnable));

            setSiteEnabling(undefined);
        } finally {
            setRequestingSiteEnable(false);
        }
    };

    const onAddNewSiteHandler = useCallback(() => {
        if (draftAvailableToActiveOnBaner) {
            setShowMessageConfirmCreatingPlus(true);
            // const available = allLoadedSites.find(
            //     (site) => site.subscription?.type === 'PLUS' && !site.site
            // );
            // console.log(available);
            // setNonPublishedRestoringDraft(available);
        } else {
            setShowAddModal(true);
        }
    }, [draftAvailableToActiveOnBaner]);

    useEffect(() => {
        loadSites();
    }, []);

    useEffect(() => {
        if (!loadingPage && showPremiumBannerLocalStorage !== 'displayed') {
            const timer = setTimeout(() => {
                localStorage.setItem('premium-banner', 'displayed');
                setShowPremiumBanner(true);
            }, 750);

            return () => clearTimeout(timer);
        }
    }, [loadingPage, showPremiumBannerLocalStorage]);

    return (
        <>
            {loadingPage ? (
                <LoadingState />
            ) : (
                <Container>
                    <HeaderBox>
                        <LogoSection>
                            <SymbolKeepoIcon letters={false} />
                        </LogoSection>
                        <div>
                            <h4>
                                {t('pages.sites.intro.hello')}, {user} 👋
                            </h4>
                        </div>
                        <div
                            onClick={() => navigate('/account')}
                            className="account"
                        >
                            <ShapeIcon />
                        </div>
                    </HeaderBox>

                    {draftAvailableToActiveOnBaner && (
                        <Box>
                            <ActiveSubscriptionBanner
                                onClick={confirmUsePlusDraftNonPublishedSite}
                            />
                        </Box>
                    )}
                    {appState.isPROUser && i18n.language === 'pt' ? (
                        <SearchBanner visible={showPremiumBanner} />
                    ) : (
                        <PremiumBanner
                            visible={showPremiumBanner}
                            draftAvailable={!!draftAvailableToActiveOnBaner}
                        />
                    )}

                    {sites.length > 0 ? (
                        <Box
                            className={classNames({
                                npt: !!draftAvailableToActiveOnBaner,
                            })}
                        >
                            <Fonts.Label>
                                {t('pages.sites.list.title')}
                            </Fonts.Label>
                            <SitesSection>
                                {sites.map((site) => (
                                    <CardSite
                                        key={site.siteId}
                                        site={site.site}
                                        siteId={site.siteId}
                                        disabled={site.disabled}
                                        onEditClick={() =>
                                            onClickEditCardSite(site)
                                        }
                                        onEnableClick={() =>
                                            setSiteEnabling(site)
                                        }
                                        setSite={setSite}
                                        subscription={site.subscription}
                                        trackingIntegrations={site.tracking}
                                    />
                                ))}
                            </SitesSection>
                        </Box>
                    ) : (
                        <EmptyState />
                    )}
                    {siteRestoringDraft && (
                        <ConfirmModal
                            type="INFO"
                            title={t(
                                'pages.sites.actions.restoreDraft.modal.title'
                            ).replace('${name}', siteRestoringDraft.site.title)}
                            confirmButtonTitle={t(
                                'pages.sites.actions.restoreDraft.modal.confirm'
                            )}
                            cancelTitle={t(
                                'pages.sites.actions.restoreDraft.modal.cancel'
                            )}
                            onExit={() => setSiteRestoringDraft(undefined)}
                            onConfirm={confirmUseDraftAndEdit}
                            onCancel={deleteDraftAndEdit}
                        />
                    )}
                    {nonPublishedRestoringDraft && (
                        <ConfirmModal
                            hideExitButton
                            type="INFO"
                            title={t(
                                `pages.sites.actions.restoreNonPublishedDraft.modal.${
                                    nonPublishedRestoringDraft.subscription
                                        ?.type || 'FREE'
                                }.title`
                            )}
                            confirmButtonTitle={t(
                                `pages.sites.actions.restoreNonPublishedDraft.modal.${
                                    nonPublishedRestoringDraft.subscription
                                        ?.type || 'FREE'
                                }.confirm`
                            )}
                            cancelTitle={t(
                                `pages.sites.actions.restoreNonPublishedDraft.modal.${
                                    nonPublishedRestoringDraft.subscription
                                        ?.type || 'FREE'
                                }.cancel`
                            )}
                            onExit={() => setSiteRestoringDraft(undefined)}
                            onConfirm={confirmUseDraftNonPublishedSite}
                            onCancel={discardDraftNonPublishedSite}
                        />
                    )}
                    {siteEnabling && (
                        <ConfirmModal
                            type="INFO"
                            title={t(
                                'pages.sites.actions.enable.modal.title'
                            ).replace('${name}', siteEnabling.site.title)}
                            confirmButtonTitle={t(
                                'pages.sites.actions.enable.modal.confirm'
                            )}
                            cancelTitle={t(
                                'pages.sites.actions.enable.modal.cancel'
                            )}
                            onExit={() => setSiteEnabling(undefined)}
                            onConfirm={enableKeepo}
                            onCancel={() => setSiteEnabling(undefined)}
                        />
                    )}
                    {showMessageConfirmCreatingPlus &&
                        draftAvailableToActiveOnBaner && (
                            <ConfirmModal
                                type="INFO"
                                title={t(
                                    'pages.sites.actions.warningUsingPlusKeepo.title'
                                )}
                                cancelTitle={t(
                                    'pages.sites.actions.warningUsingPlusKeepo.cancel'
                                )}
                                confirmButtonTitle={t(
                                    'pages.sites.actions.warningUsingPlusKeepo.confirm'
                                )}
                                onConfirm={confirmUsePlusDraftNonPublishedSite}
                                onCancel={() =>
                                    setShowMessageConfirmCreatingPlus(false)
                                }
                                hideExitButton
                            />
                        )}
                    {isShowAddModal && (
                        <NewSiteModal
                            onClose={() => setShowAddModal(false)}
                            createNewSite={createNewSiteHandler}
                        />
                    )}
                    <Footer onAdd={onAddNewSiteHandler} />
                </Container>
            )}
            {isRequestingSiteEnable && <LoadingModal />}
        </>
    );
};

export default SitesPage;
