import SearchBannerIcon from '../../../../icons/SearchBannerIcon';
import { cn } from '../../../../utils/cn';

interface SearchBannerProps {
    visible: boolean;
}

export function SearchBanner({ visible = false }: SearchBannerProps) {
    return (
        <a
            className={cn('search-banner-content float-banner', {
                visible: visible,
            })}
            href="https://forms.gle/6k79zNBEiBuGWYxWA"
            target="_blank"
        >
            <section className="search-banner">
                <div>
                    <div>
                        <h4>Queremos ouvir você!</h4>
                        <p>Leva menos que 3 minutos.</p>
                    </div>
                    <a
                        href="https://forms.gle/6k79zNBEiBuGWYxWA"
                        target="_blank"
                    >
                        Participar
                    </a>
                </div>
                <SearchBannerIcon />
            </section>
        </a>
    );
}
